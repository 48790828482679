.work-area-main-wrap {
  padding: 80px 0;
  padding-top: 0px;
  .work-area-title {
    text-align: center;
    margin-bottom: 30px;
    h2 {
      font-size: 36px;
      font-weight: 800;
      line-height: 46px;
    }
    p {
      color: #ef4138;
      margin-bottom: 15px;
      font-weight: 500;
    }
  }
  .nav-tabs .nav-link {
    margin-bottom: 0;
    background: none;
    border: none;
    border-top-left-radius: unset;
    border-top-right-radius: unset;
    padding-left: 0;
    padding-right: 0;
    color: #0b141f;
    &.active {
      font-weight: 800;
      position: relative;
      color: #0b141f;
      &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        background: #ef4138;
        bottom: -1px;
        left: 0;
      }
    }
  }
  .nav.nav-tabs {
    justify-content: center;
    border-bottom: none;
    gap: 50px;
  }

  .work-inner-single-items {
    text-align: center;
    &.work-image-item-1 {
      img {
        background: #f0f6ff;
        border-radius: 10px;
      }
    }
    &.work-image-item-2 {
      img {
        background: #fffbf0;
        border-radius: 10px;
      }
    }
    &.work-image-item-3 {
      img {
        background: #f2f0ff;
        border-radius: 10px;
      }
    }
    margin: 40px 0;
    img {
      width: 100%;
      display: inline-block;
      margin-bottom: 25px;
      padding: 20px;
    }
    h3 {
      font-size: 24px;
      font-weight: 700;
      line-height: 30px;
      margin-bottom: 15px;
    }
    p {
      font-size: 18px;
      line-height: 30px;
      font-weight: 500;
      padding: 0px 30px;
    }
  }
  .row {
    justify-content: space-between;
    .col-lg-4 {
      flex: 0 0 auto;
      width: 30.333333%;
    }
  }
}
