header.header-main-area {
  position: fixed;
  z-index: 999;
  width: 100%;
  top: 0;
  left: 0;
  background: #fff;
  box-shadow: 0px 0px 20px 0px #efefef;
  &.active {
    background: #fff;
  }
  .header-inner-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    position: relative;
    .header-navbar > ul {
      display: flex;
      align-items: center;
      gap: 20px;

      li {
        .navbar__link.header-login-btn {
          color: #fff;
          background: #ef4138;
          padding: 8px 20px;
          border-radius: 5px;
          font-size: 14px;
        }
        a {
          text-decoration: none;
          color: #000;
          font-size: 14px;
          font-weight: 600;
          cursor: pointer;
        }
      }
    }
    .menuToggle {
      width: 30px;
      position: absolute;
      right: 0;
      display: flex;
      align-items: center;
      top: 15px;
      height: 30px;
      display: none;
      button {
        width: 100%;
        height: 30px;
        background: none;
        border: none;
        outline: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .navbar_mobile_menu_bar {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 7px;
        width: 100%;
        top: 10px;
        position: absolute;
        padding: 0;
        span {
          display: block;
          width: 100%;
          height: 2px;
          background: #000;

          top: 50%;
          left: 0;
          transform: translateY(-50%);
          transition: all 0.3s ease 0s;
        }
      }
      .navbar_mobile_close.remove {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column;
        gap: 7px;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 8px;
        span {
          display: block;
          width: 100%;
          height: 2px;
          background: #000;

          top: 50%;
          left: 0;
          transform: translateY(-50%);
          transition: all 0.3s ease 0s;
          &:nth-child(1) {
            transform: translateY(-50%) rotate(46deg);
            position: absolute;
            top: 16px;
          }
          &:nth-child(2) {
            transform: translateY(50%) rotate(-45deg);
            position: absolute;
          }
          &:nth-child(3) {
            display: none;
          }
        }
      }
    }
  }
}
.header-logo img {
  height: 45px;
}
