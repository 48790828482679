.prduct-review-area-main-wrap {
  padding: 80px 0;
  background: #fafafb;
  padding-top: 40px;
  .product-review-title {
    margin-bottom: 60px;
    text-align: center;
    p {
      font-weight: 400;
      margin-bottom: 15px;
      color: #ef4138;
    }
    h2 {
      font-size: 36px;
      font-weight: 800;
      color: #000;
    }
  }
  .playstore-icon {
    text-align: center;
    text-align: center;
    margin-bottom: 30px;
    img {
      width: 60px;
      border: 2px solid #ef4138;
      padding: 10px;
      height: 60px;
      border-radius: 10px;
      margin-bottom: 25px;
    }
    h3 {
      margin-bottom: 20px;
      font-weight: 800;
      font-size: 24px;
    }
    .feedback-rating {
      span {
        font-size: 18px;

        i {
          color: #f49e1c;
        }
      }
    }
  }
  .product-review-content {
    display: flex;
    justify-content: space-between;
    background: #fff;
    padding: 50px;
    border-radius: 15px;
    box-shadow: 0px 30px 60px #0000000d;
    > * {
      width: 40%;
    }
    .review-content-devider {
      width: 2px !important;
      background: #e1e1e1;
    }
  }

  .review-feedback-information-wrap {
    padding: 0 60px;
    .review-feedback-information {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
      > * {
        width: 50%;
      }
    }
    .playstore-download-button {
      background: #fff;
      padding: 12px 30px;
      color: #ef4138;
      border: 1px solid #ef4138;
      text-decoration: none;
      border-radius: 10px;
      transition: 0.2s;
      z-index: 11;
      position: relative;
      display: block;
      text-align: center;
      margin: auto;
      margin-top: 40px;
      width: 200px;
      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 8px;
        z-index: -1;
        transition: 0.2s;
        border-right: 3px solid #ef4138;
        border-bottom: 3px solid #ef4138;
      }
      &:hover {
        &:after {
          border-right: 0px solid transparent;
          border-bottom: 0px solid transparent;
          border-top: 3px solid #ef4138;
          border-left: 3px solid #ef4138;
        }
      }
    }
  }
}
