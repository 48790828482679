.about-main-area-wrap {
  padding: 80px 0;
  padding-top: 20px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  .about-right-area {
    .about-title {
      p {
        color: #ef4138;
        margin-bottom: 15px;
        font-weight: 500;
      }
    }
    .about-content {
      > h4 {
        margin-bottom: 30px;
        font-size: 36px;
        font-weight: 700;
        line-height: 46px;
      }
      > p {
        font-size: 16px;
        line-height: 30px;
        margin-bottom: 50px;
      }
      hr {
        color: #f0f0f0;
        opacity: 1;
        margin-bottom: 40px;
      }
      .about-profile-info-bottom {
        display: flex;
        gap: 40px;
        align-items: center;
        margin-top: 20px;
        .about-profile-info-bottom-left-icon img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
        }
      }
    }

    .about-left-area img {
      width: 94%;
    }
  }
  .about-area-bottom-info {
    .about-bottom-single-item {
      text-align: center;
      height: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      h3 {
        margin-bottom: 10px;
        color: #fff;
        font-weight: 800;
      }
      p {
        color: #fff;
      }
      i {
        svg {
          width: 34px;
          height: 34px;
          margin-bottom: 15px;
          path {
            stroke: #fff;
          }
        }
      }
      &.box-one-item {
        background: #ef4138;
      }
      &.box-two-item {
        background: #f1b44c;
      }
      &.box-three-item {
        background: #34c38f;
      }
      &.box-four-item {
        background: #8e43c9;
      }
    }
  }
  .about-area-bottom-info {
    padding-top: 60px;
  }
  .about-profile-info-bottom-left-content {
    p {
      font-size: 18px;
      margin-bottom: 15px;
      line-height: 28px;
      font-weight: 500;
    }
    h5 {
      font-weight: 700;
      font-size: 20px;
    }
  }
}
