.pricing-table-main-area-wrap {
  padding: 80px 0;
  background: #fafcff;
  .pricing-table-top-header-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 60px;
    .pricing-table-title {
      p {
        color: #ef4138;

        font-weight: 500;
        margin-bottom: 15px;
      }
      h2 {
        color: #000;
        font-size: 36px;
        font-weight: 700;
        line-height: 46px;
      }
    }
    .price-save-area-right {
      > p {
        margin-bottom: 15px;
      }
      .monthly-yearly-area {
        display: flex;
        align-items: center;
        gap: 20px;
        p {
          color: #000;
          font-size: 16px;
          font-weight: 400;
          margin-bottom: 5px;
        }
      }
    }
  }
  .pricing-table-single-item {
    border: 1px solid #ddd;
    padding: 40px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    position: relative;
    background: #fff;
    &.recommended {
      border: 1px solid #ef4138;
      overflow: hidden;
      .recommended-package {
        position: absolute;
        top: 33px;
        right: -34px;
        background: #ef4138;
        color: #fff;
        font-size: 13px;
        text-transform: uppercase;
        transform: rotate(45deg);
        padding: 3px 30px;
      }
    }
    .pricing-table-single-item-header {
      //   margin-bottom: 20px;

      h3 {
        color: #000;
        font-size: 26px;
        font-weight: 700;
        line-height: 30px;
        margin-bottom: 20px;
      }
      p {
        color: #000;
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 20px;
      }
      h2 {
        color: #ef4138;
        font-size: 36px;
        font-weight: 700;
        line-height: 30px;
        margin-bottom: 30px;
        span {
          color: #000;
          font-size: 18px;
          font-weight: 400;
          margin-bottom: 10px;
        }
      }
    }
    .pricing-table-single-item-body {
      .pricing-table-features {
        ul {
          list-style: none;
          li {
            color: #000;
            font-size: 16px;
            font-weight: 400;
            margin-bottom: 16px;
            display: flex;
            align-items: center;
            gap: 15px;
            i {
              color: #ef4138;
            }
          }
        }
      }
    }
    .pricing-table-single-item-footer {
      text-align: center;
      * {
        padding: 12px 30px;
        background: #fff;
        border-radius: 10px;
        color: #ef4138;
        font-weight: 600;
        border: 1px solid #ef4138;
        position: relative;
        transition: all 0.3s ease 0s;
        overflow: hidden;
        width: 220px;
        display: inline-block;
        text-decoration: none;
        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          //   background: #000;
          border-radius: 8px;
          //   z-index: -1;
          transition: 0.2s;
          border-right: 3px solid #ef4138;
          border-bottom: 3px solid #ef4138;
        }
        &:hover {
          &:after {
            border-right: 0px solid transparent;
            border-bottom: 0px solid transparent;
            border-top: 3px solid #ef4138;
            border-left: 3px solid #ef4138;
          }
        }
      }
    }
  }
}
