.our-pertner-main-area-wrap {
  padding: 80px 0;
  padding-top: 40px;
  .our-pertner-title {
    margin-bottom: 60px;
    text-align: center;
    h6 {
      color: #ef4138;
      font-size: 32px;
      font-weight: 600;
      margin-bottom: 20px;
    }
  }
  .pertner-slider-single-item {
    img {
      height: 50px;
      width: unset;
      margin: auto;
    }
  }
}
