.blog-main-area-wrap {
  padding: 80px 0;
  padding-top: 30px;
  .blog-area-title {
    text-align: center;
    margin-bottom: 80px;
    p {
      font-weight: 500;
      color: #ef4138;
      margin-bottom: 15px;
    }
    h2 {
      font-size: 36px;
      font-weight: 800;
      color: #000;
    }
  }
  .blog-bottom-content {
    padding: 30px 10px;
    padding-bottom: 15px;
    a {
      text-decoration: none;
    }
    h4 {
      font-size: 22px;
      font-weight: 800;
      color: #000;
      margin-bottom: 10px;
    }
    p {
      font-size: 14px;
      font-weight: 500;
      color: #000;
    }
  }
  .blog-content-footer {
    display: flex;
    justify-content: space-between;
    padding: 15px 10px;
    & > * {
      display: flex;
      align-items: center;
      gap: 5px;
      i {
        font-size: 18px !important;
        color: #ccced0;
      }
      &:last-child {
        i {
          font-size: 30px;
          color: #ef4138;
        }
      }
    }
  }
  // .blog-area-content > .row {
  //   justify-content: space-between;
  //   > * {
  //     width: 48%;
  //   }
  // }
}
