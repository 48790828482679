.product-feature-main-area-wrap {
  padding: 80px 0;
  padding-bottom: 0px;
  .product-feature-title {
    text-align: center;
    margin-bottom: 90px;
    h2 {
      font-size: 36px;
      font-weight: 800;
      color: #000;
    }
    p {
      color: #ef4138;
      margin-bottom: 14px;
      font-weight: 500;
    }
  }
  .single-product-feature {
    h4 {
      font-weight: 700;
    }
    &.left {
      text-align: right;
      margin-bottom: 60px;
      .product-feature-icon {
        width: 60px;
        height: 60px;
        background: #f2827c;
        box-shadow: 0px 4px 6px 2px rgb(0 0 0 / 10%);
        border-radius: 11px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        margin-bottom: 25px;
        color: #fff;
        font-size: 22px;
      }
      .product-feature-content h4 {
        margin-bottom: 15px;
        font-size: 20px;
      }
    }
    &.right {
      text-align: left;
      margin-bottom: 60px;
      .product-feature-icon {
        width: 60px;
        height: 60px;
        background: #f2827c;
        box-shadow: 0px 4px 6px 2px rgb(0 0 0 / 10%);
        border-radius: 11px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: auto;
        margin-bottom: 25px;
        color: #fff;
        font-size: 22px;
      }
      .product-feature-content h4 {
        margin-bottom: 15px;
        font-size: 20px;
      }
    }
    &.middle {
      text-align: center;
      img {
        width: 75%;
        padding: 0px 30px;
        position: relative;
        left: -8px;
        top: -85px;
      }
    }
  }
}
