.product-analytics-main-area-wrap {
  padding: 80px 0;
  .product-analytics-title {
    text-align: center;
    margin-bottom: 60px;
    h2 {
      font-size: 36px;
      font-weight: 800;
      line-height: 46px;
    }
    p {
      margin-bottom: 15px;
      color: #ef4138;
      font-weight: 500;
    }
    h3 {
      font-size: 24px;
      margin-top: 12px;
    }
  }
  .accordion-item {
    color: unset;
    border: none;
    border-bottom: 1px solid #ddd;
    padding: 10px 0;
    .accordion-button {
      padding-left: 0;
      font-size: 16px;
      font-weight: 700;
      &:focus {
        z-index: 3;
        border-color: none;
        outline: 0;
        box-shadow: none;
      }
      &:not(.collapsed) {
        background: #fff;
        border-color: #86b7fe;
        box-shadow: none;
        color: #000;
      }
    }

    .accordion-body {
      padding-top: 0;
      padding-left: 0;
    }
  }
  .product-analytics-right-area img {
    width: 100%;
    border-radius: 10px;
  }
  .accordion-button::after {
    content: "\f178";
    // font-family: "Font Awesome 5 Pro";
    font: var(--fa-font-solid);
    background: none;
    font-weight: 700;
  }
  .accordion-button:not(.collapsed)::after {
    background-image: none;
    transform: unset;
  }
  .product-analytics-right-area {
    height: 450px;
    overflow-y: scroll;
    /* width */
    &::-webkit-scrollbar {
      width: 1px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
  }
}

//==================================
.desktop-hide-mobile-show-area.accordion-body {
  display: none;
}
@media (max-width: 991px) {
  .desktop-hide-mobile-show-area.accordion-body {
    display: block;
    max-height: 400px;
    overflow-y: scroll;
  }
  .product-analytics-right-area {
    display: none;
  }
  .product-analytics-main-area-wrap .accordion-button:not(.collapsed)::after {
    transform: rotate(90deg);
  }
}
